<template>
  <div id="home">

    <header>
      <a class="back" href="http://hetnieuweinstituut.nl">
        <img src="../assets/exit.png" />
      </a>
      <div :class="['info', { desired: desiresInfo }]">
        <div class="content">
          <p>This codebase has been initially developed as part of a collaboration between <a target="_blank" href="https://github.com/hackersanddesigners/the-hmm-livestream/blob/master/LICENSE#L3">Hackers & Designers and The Hmm</a>.
          <br><br>Het Nieuwe Instituut aims to offer the safest possible environment for all its online visitors, participants and users. In this public space, treat each other with attention and respect, put yourself in the position, (lived) experiences and ideas of other users and try to learn from each other in this way. Check the complete code of conduct of Het Nieuwe Instituut <a target="_blank" href="https://hetnieuweinstituut.nl/en/code-of-conduct">here
          </a>.</p>

        </div>
        <div
          class="button"
          @click="desiresInfo=!desiresInfo"
        >
          <span>i</span>
        </div>
      </div>
    </header>

    <nav>
      <div class="logotype">
        <span> Het Nieuwe Instituut</span>
      </div>
    </nav>

    <main>
      <div id="videoContainer">
        <div class="header">
          {{ isMobile ? 'Het Nieuwe Instituut' : 'Livestream' }}
        </div>
        <Video
          :stream="stream"
          :muted="muted"
          :playing="playing"
          :fullscreen="fullscreen"
          @unfullscreened="fullscreen = false"
        />
        <Status
          v-if="stream.status === 'active' && muted && !isMobile"
          :status="'Click to unmute'"
          @click="muted = false"
        />
      </div>
      <Chat />
    </main>

  </div>
</template>

<script>
import Video from '../components/Video'
import Chat  from '../components/Chat'
import Status from '../components/Status.vue'

import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Video,
    Chat,
    Status,
  },
  data() {
    return {
      muted: true,
      playing: true,
      fullscreen: false,
      desiresInfo: false,
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'stream',
    ])
  }

}
</script>

<style>

#home {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

header {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

header .back {
  height: 40px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: auto;
}

header .back img {
  position: relative;
  height: 100%;
  width: auto;
  max-width: 50px;
}

header .info {
  max-height: 40px;
  background: var(--back);
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid transparent;
  /* border-right: 0; */
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  transition: all 0.2s ease;
}

header .info .content {
  box-sizing: border-box;
  align-self: flex-start;
  width: 100%;
  /* height: 100%; */
  /* max-height: 100%; */
  max-width: 0px;
  font-size: 9pt;
  opacity: 0;
  transition: all 0.2s ease;
  padding: 5px;
}

header .info .content p {
  margin: 0;
  max-height: 30px;
  overflow: hidden;
}

header .info .button {
  box-sizing: border-box;
  user-select: none;
  flex-shrink: 0;
  border: 1px solid black;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 25pt;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
header .info.desired {
  border: 1px solid black;
  /* border-right: 0; */
  max-height: 200px;
}

header .info.desired .content {
  box-sizing: border-box;
  max-height: 200px;
  max-width: 420px;
  opacity: 1;
}
header .info.desired .content p {
  max-height: 120px;
}




nav {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav .logotype {
  position: relative;
  height: 100%;
  padding-top: 40px;
  display: flex;
  align-items: center;
}

nav .logotype span {
  display: block;
  font-size: 41pt;
  width: 80vh;
  overflow: visible;
  transform: rotate(-90deg);
  text-align: center;
}

main {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* height: 70%; */
  min-height: 70%;
  max-height: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* align-items: flex-start; */
  /* padding-bottom: 56.25%; */
}

main #videoContainer {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 1em;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  overflow: hidden;
}

main #videoContainer .header {
  position: relative;
  box-sizing: border-box;
  padding: 0.3em;
  text-align: center;
  border-bottom: 1px solid black;
}

.mobile #home {
  height: 100%;
  align-items: stretch;
}
.mobile nav {
  display: none;
}
.mobile main {
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  /* justify-content: stretch; */
  /* align-items: stretch; */
  justify-content: flex-start;
  overflow: hidden;
}
.mobile header {
  padding: 5px;
  /* height: 35px; */
}
.mobile header .info {
}
.mobile header .back {
  height: 25px;

}
.mobile header .info .button {
  font-size: 14pt;
  height: 25px;
  width: 25px;
}
.mobile header .info {
  max-height: 25px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.mobile header .info .content p,
.mobile header .info.desired,
.mobile header .info.desired .content {
  max-height: 200px;
}
.mobile header .info.desired .content p {
  /* max-height: 40px; */
}
.mobile #videoContainer {
  flex: 0 0 auto;
  margin: 0;
  height: unset;
}

</style>