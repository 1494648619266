<template>
  <video
    autoplay
    :muted="muted"
    :controls="controls"
  >
  </video>
</template>
<script>
import Hls from 'hls.js'

export default {
  name: 'Video',
  props: [
    'stream',
    'muted',
    'playing',
    'fullscreen'
  ],
  data() {
    return {
      controls: false,
    }
  },
  computed: {
    active() { return this.stream.status === 'active' },
    isMobile() { return this.$store.state.isMobile },
  },
  watch: {
    stream(newState) {
      if (newState.status === 'active') {
        this.updateVideo()
      } else {
        // this.$el.src = ''
        // this.$el.pause()
      }
    },

    playing(newState) {
      if (newState) {
        this.$el.play()
      } else {
        this.$el.pause()
      }
    },

    muted(newState) {
      if (newState) {
        this.$el.muted = true
      } else {
        this.$el.muted = false
      }
    },

  },
  mounted() {

    if (this.playbackId) {
      this.updateVideo()
    }

  },
  methods: {

    src:    playbackId => `https://stream.mux.com/${playbackId}.m3u8`,
    poster: playbackId => `https://image.mux.com/${playbackId}/thumbnail.jpg?time=15`,


    updateVideo() {

      this.controls = true

      const playbackId = this.stream.playbackId
      const sourceUrl  = this.src(playbackId)

      // If HLS.js is supported on this platform

      if (Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(sourceUrl)
        hls.attachMedia(this.$el)
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.$el.play()
        })


      // If the player can support HLS natively

      } else if (this.$el.canPlayType('application/vnd.apple.mpegurl')) {
        this.$el.src = sourceUrl
        this.$el.addEventListener('loadedmetadata', () => {
          this.$el.play()
        })
      }

    }
  }
}
</script>

<style>
video {
  position: relative;
  box-sizing: border-box;
  /* width: 100%; */
  height: 100%;
  /* height: auto; */
  max-width: 100%;
  max-height: 100%;
  /* object-fit: contain; */
  /* background: var(--back); */
  background: black;
  animation: comein 0.2s ease-in;
  overflow: hidden;
}
@keyframes comein {
  0% { opacity: 0;}
  100% { opacity:1;}
}
.mobile video {
  object-fit:unset;
  height: unset;
}
</style>
