import { createApp }  from 'vue'
import App            from './App'
import Axios          from 'axios'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io }         from 'socket.io-client'
import VueMarkdownIt    from 'vue3-markdown-it'
import router         from './router'
import store          from './store'

const url = 'https://stream.nieuweinstituut.nl'

const app = createApp(App)

app.config.globalProperties.$url  = url
app.config.globalProperties.$path = '/' + process.env.VUE_APP_NAME
app.config.globalProperties.$http = Axios

app
  .use(VueMarkdownIt)
  .use(VueSocketIOExt, io(url), { store })
  .use(router)
  .use(store)
  .mount('#app')
