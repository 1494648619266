<template>
  <div class="status">
    <span> {{ status }} </span>
  </div>

</template>

<script>
export default {
  name: 'Status',
  props: [
    'status'
  ]

}
</script>

<style>
.status {
  box-sizing: border-box;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.87;
}

.status span {
  box-sizing: border-box;
  background: var(--me);
  padding: 2.5em;
  border-radius: 0.5em;
  cursor: pointer;
}
</style>