import { createStore } from 'vuex'

export default createStore({

  strict: process.env.NODE_ENV !== 'production',

  state: {
    isMobile : false,
    visitors : 0,
    stream   : { status: 'idle' },
    chat     : [],
    isAdmin  : false,
  },

  mutations: {
    setMobile   : (state, mobile)  => state.isMobile = mobile,
    setVisitors : (state, count)   => state.visitors = count,
    setStream   : (state, stream)  => state.stream   = stream,
    setChat     : (state, chat)    => state.chat     = chat,
    addMessage  : (state, message) => state.chat.push(message),
    setAdmin    : (state, isAdmin) => state.isAdmin = isAdmin
  },

  actions: {
    socket_streamUpdate({ commit }, data) {
      console.log('STREAM:', data.status)
      commit('setStream', data)
    },
    socket_users({ commit }, count) {
      commit('setVisitors', count)
    },
  },

  getters: {
    chatByTime: state => {
      return state
      .chat
      .filter(m => m.time > 0)
      .filter(m => m.content !== '/isAdmin')
      .sort((a, b) => (
          a.time - b.time
      ))
    },
  }

})
