<template>
  <div id="chatContainer">
    <div class="header">
      Chat
    </div>

    <div
      id="chat"
      ref="chat"
      :class="{ registered: name }"
    >
      <Message
        v-for="message in chatByTime"
        :key="message.time"
        :message="message"
      />
    </div>

    <div id="chatInput">
      <input
        v-if="!name"
        class="nameinput"
        type="text"
        ref="name"
        placeholder="First Lastname"
      />
      <textarea
        ref="message"
        class="messageinput"
        placeholder="Type a message..."
        @keydown.enter="send"
      />
      <!-- <div class="row">
        <input
          type="button"
          ref="send"
          class="send"
          value="send"
          @click="send()"
        />
      </div> -->
    </div>

  </div>
</template>

<script>
import Message from './Message'

export default {

	name: 'Chat',

  components: {
    Message,
  },

  data() {
    return {
      name: null,
      users: 1,
    }
  },
  computed: {
    chatByTime() { return this.$store.getters.chatByTime },
    isAdmin() { return this.$store.state.isAdmin }
  },

  created() {

    let name = localStorage.name
    if (name) {
      this.name = JSON.parse(name)
    }

    this.$http
      .get(this.$url + '/posts')
      .then(response => {
        const messages = response.data
        if (this.name) {
          messages.forEach(m => {
            if (m.author === this.name) {
              m.isMe = true
            }
          })
        }
        this.$store.commit('setChat', messages)
        setTimeout(() => {
          this.scrollToBottom(true)
          this.blankify('.messageContainer a')

        }, 500)
      })
      .catch(error => {
        console.log(error)
      })

  },

  sockets: {

      message(message) {
        if (this.name && message.author === this.name) {
          message.isMe = true
        }
        this.$store.commit('addMessage', message)
        this.scrollToBottom(true)
        this.blankify(`#message${message.time} a`)
      },

      users(users) {
        this.users = users
      }

  },

  methods: {

    send(e) {
      if (e) e.preventDefault()

      const
        name    = this.name || this.$refs.name.value,
        input   = this.$refs.message,
        isAdmin = localStorage.isAdmin || input.value === '/isAdmin'


      if (name && input.value) {
        if (!this.name) {
          localStorage.name = JSON.stringify(name)
          this.name = name
        }
        if (isAdmin) {
          this.$store.commit('setAdmin', isAdmin)
          localStorage.isAdmin = isAdmin
        }

        this.$socket.client.emit('message', {
          author: name,
          content: input.value,
          time: ((new Date()).getTime()),
          isAdmin: this.isAdmin,
        })

        input.value = null

      }

    },

    scrollToBottom(first) {
      setTimeout(() => {
        this.$refs.chat.scroll({
          top: this.$refs.chat.scrollHeight,
          behavior: 'smooth'
        })
      }, first ? 100 : 0)
    },

    blankify(selector) {
      setTimeout(() => {
        Array
        .from(document.querySelectorAll(selector))
        .forEach(a => {
          if (a && !a.target) {
            a.target = "_blank"
          }
        })
      }, 1000)

    }

  }

}

</script>

<style scoped>

#chatContainer {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 350px;
  max-width: 350px;
  flex-shrink: 0;
  margin: 1em;
  margin-left: 0;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

#chatContainer .header {
  box-sizing: border-box;
  position: relative;
  padding: 0.3em;
  border-bottom: 1px solid black;
  text-align: center;
}

#chat {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.messageContainer:last-of-type {
  margin-bottom: 0.5em;
}

#chatInput {
  position: relative;
  background: transparent;
}

#chatInput input,
#chatInput textarea {
  box-sizing: border-box;
  font-size: 14pt;
  font-family: inherit;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  margin: 0px;
  padding: 0.3em;
  border-top: 1px solid black;
  transition: all 0.2s ease, background-color 0.3s ease;
  resize: none;
}
#chatInput input::placeholder {
  font-size: 14pt;
}
#chatInput input[type="button"] {
  width: unset;
  cursor: pointer;
  margin-bottom: 0;
  -webkit-appearance: unset;
}

#chatInput input:hover {
}
#chatInput input:focus,
#chatInput input:active {
}
#chatInput .row {
  display: flex;
}
#chatInput input.nameinput {
  background: var(--me);
}

.mobile #chatContainer {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
  overflow: scroll;
  flex-shrink: 1;
  flex-grow: 1;
}
.mobile #chat {
  /* width: 100%;
  padding: 0px 10px;
  position: absolute;
  bottom: 0; */
  height: auto;
  flex: 0 1 auto;
  overflow: scroll;
}

.mobile .messageContainer:last-of-type {
  margin-bottom: 0.5em;
}

.mobile #chat {
  flex-grow: 1;
}

.mobile #chatInput {
  /* position: fixed; */
  /* bottom: 0; */
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile #chatInput input {
  padding: 10px 14px 8px 14px;
  font-size: 22px;
  width: unset;
}
.mobile #chatInput input.nameinput {
  width: 100%;
}
.mobile #chatInput input.messageinput {
  width:100%;
}
.mobile #chatInput input.send {
  /* width: calc(30% - 10p  x); */
}
.mobile #chatInput .row {
  width: 100%;
}
.mobile #chatInput .row .users {
  margin-left: auto;
}

</style>