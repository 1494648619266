import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home'

const path = '/' + process.env.VUE_APP_NAME

export default createRouter({
  history: createWebHistory(path),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
  ],
})


