<template>
  <div
    :class="['messageContainer', {
      mine:  message.isMe,
      admin: message.isAdmin
    }]"
    :id="`message${message.time}`"
  >

    <div class="message bubble">
      <span class="author">{{ message.isMe ? 'You' : author }}</span>
      <span>{{ message.isMe ? ' say' : ' says' }}: </span>
      <vue3-markdown-it
        class="content"
        :source="content"
        v-bind="options"
        />
    </div>

    <!-- <div class="footer bubble">
      <span class="time">
       {{ time }}
      </span>
    </div> -->

  </div>
</template>

<script>
import moment from 'moment'

export default {

	name: 'Message',
	props: [ 'message' ],
  data() {
    return {
      time: null,
      options: {
        linkify: true,
        typographer: true,
      }
    }
  },
  computed: {
    author() {
      return this.message.author
    },
    content() {
      return this.message.content
    }
  },
  created() {
    this.time = this.getTime()
    setInterval(() => {
      this.time = this.getTime()
    }, 60000)
  },
  methods: {
    getTime() {
     return moment(this.message.time).fromNow()
    }
  }
}
</script>

<style>
.messageContainer {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  animation: comein 0.2s ease-in;
  margin: 0.5em;
  margin-bottom: 0;
  padding: 0.5em;
  border-radius: 0.5em;
  background: var(--hni);
}
.messageContainer.mine {
  background: var(--me);
}
.messageContainer.mine.admin,
.messageContainer.admin {
  background: var(--admin);
}
@keyframes comein {
  0% { opacity: 0;}
  100% { opacity:1;}
}

.messageContainer .bubble {
  box-sizing: border-box;
  overflow: hidden;
  border: 0px solid;
  transition: all 0.2s ease, background-color 0.3s ease;
}

.message {
  padding: 0px 2px;
  border-radius: 10px;
  overflow: visible;
}

.message .content {
  box-sizing: border-box;
  /* word-break: break-all; */
  display: inline;
}

.messageContainer .footer {
  margin-top: 8px;
  box-sizing: border-box;
  font-size: 8pt;
  height: 0px;
  line-height: 0;
  overflow: visible;
  flex-shrink: 0;
  border-radius: 10px;
}

.message .author {
  /* text-decoration: underline; */
}

.message .time {
  font-style: italic;
  font-size: 7pt;
}

.messageContainer .content *,
.messageContainer .author *,
.messageContainer .time * {
  font-size: inherit;
  margin: 0;
  padding: 0;
  display: inline;
}

</style>