<template>
  <div id="app" :class="{ mobile: isMobile }">

    <router-view v-slot="{ Component }">
      <transition name="component-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState([
      'isMobile',
    ])
  },
  created() {

    this.$store.commit('setMobile', this.checkIfMobile())
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', this.checkIfMobile())
    })

    this.getStreams()


  },


  methods: {

    checkIfMobile: () => window.innerWidth < 700,

    getStreams() {

      this.$http
      .get(this.$url + '/stream')
      .then(response => {
        this.$store.commit('setStream', response.data)
        console.log('STREAM:', response.data.status)
      })
      .catch(error => {
        console.log(error)
      })

    },

  }
}
</script>

<style>

@font-face {
  font-family: 'textbook';
  src: url('./assets/helvetica-textbook/HelveticaTextbookLTRoman.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'textbook';
  src: url('./assets/helvetica-textbook/HelveticaTextbookStd-Bold.otf');
  font-weight: bold;
}
@font-face {
  font-family: 'textbook';
  src: url('./assets/helvetica-textbook/HelveticaTextbookStd-Obl.otf');
  font-weight: normal;
  font-style: italic;
}

:root {
  /* --back: #c2e6d1; */
  --back: #f3faf6;
  --me: #00d48c;
  --hni: #c2e6d1;
  --admin: rgb(209, 113, 209);
}

html,
body,
#app {
  height: 100%; width: 100%;
  padding: 0; margin: 0;
  background: var(--back);
}

#app {
  height: 100%; width: 100%;
  font-family: 'textbook', Arial, Helvetica, sans-serif;
  font-size: 14pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}
</style>
